import React from "react"
import { Parallax } from 'react-scroll-parallax'

import "../Elements.css"

// images
import PrimitiveOne from '../../images/PrimitiveOne.png'
import PrimitiveTwo from '../../images/PrimitiveTwo.png'
import PrimitiveThree from '../../images/PrimitiveThree.png'
import PrimitiveFour from '../../images/PrimitiveFour.png'
import PrimitiveFive from '../../images/PrimitiveFive.png'
import PrimitiveSix from '../../images/PrimitiveSix.png'
import PrimitiveSeven from '../../images/PrimitiveSeven.png'
import PrimitiveEight from '../../images/PrimitiveEight.png'
import PrimitiveNine from '../../images/PrimitiveNine.png'
import PrimitiveTen from '../../images/PrimitiveTen.png'
import PrimitiveEleven from '../../images/PrimitiveEleven.png'
import PrimitiveTwelve from '../../images/PrimitiveTwelve.png'

const Elements = () => {
  return (
    <>
      <Parallax className="elements elements--one" y={[-100, 100]}>
        <img src={PrimitiveOne} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--two" y={[-100, 100]}>
        <img src={PrimitiveTwo} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--three" y={[-50, 50]}>
        <img src={PrimitiveThree} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--four" y={[-50, 50]}>
        <img src={PrimitiveFour} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--five" y={[-100, 100]}>
        <img src={PrimitiveFive} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--six" y={[-100, 100]}>
        <img src={PrimitiveSix} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--seven" y={[-50, 50]}>
        <img src={PrimitiveSeven} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--eight" y={[-100, 100]}>
        <img src={PrimitiveEight} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--nine" y={[-50, 50]} x={[0, 50]}>
        <img src={PrimitiveNine} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--ten" y={[-50, 50]} x={[-50, -10]}>
        <img src={PrimitiveTen} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--eleven" y={[-50, 50]}>
        <img src={PrimitiveEleven} alt="Payme" />
      </Parallax>

      <Parallax className="elements elements--twelve" y={[-100, 100]}>
        <img src={PrimitiveTwelve} alt="Payme" />
      </Parallax>
    </>
  )
}

export default Elements
